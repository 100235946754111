<div class="footer">
  <div class="footer-bg"></div>
  <div class="top">
    <div class="wrapper top-content">
      <div class="logo">
        <img src="{{logoWhite}}" />
      </div>
      <div class="images">
        <a target="_blank" href="https://www.facebook.com/trust.patundshmeri">
          <span class="material-icons">facebook</span>
        </a>
        <a target="_blank" href="https://www.instagram.com/trust.patundshmeri/">
          <img src="{{instagramUrl}}" />
        </a>
        <p>trust.patundshmeri@gmail.com</p>
      </div>
    </div>
  </div>
  <div class="bottom">
    <p>Copyright © 2021 TRUST Sh.P.K  All Rights Reserved.</p>
  </div>
</div>
