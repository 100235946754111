<header class="ms-header">
    <h1 class="ms-header__title">
      <div class="ms-slider">
        <ul class="ms-slider__words">
          <li class="ms-slider__word">Ju mund të na besoni</li>
          <li class="ms-slider__word">Lëvizje e Mençur</li>
          <li class="ms-slider__word">Trust Real Estate</li>
          <!-- This last word needs to duplicate the first one to ensure a smooth infinite animation -->
          <li class="ms-slider__word">Ju mund të na besoni</li>
        </ul>
      </div>
    </h1>
  </header>