<div class="about-us">
  <div class="wrapper">
    <div class="text">
      <p class="title">Për ne</p>
      <p class="desc">{{configs.about_us}}</p>
    </div>

    <div>
      <div class="team">
        <div class="team-member-title">
          <p>Ekipa Jonë</p>
        </div>

        <div class="team-members">
          <div class="cards">
            <div class="card">
              <div class="image" style="background-image: url('/assets/images/team/blerta.png')"></div>
              <p class='name'>Blerta Rafuna Koci</p>
              <p class="position">CEO</p>
            </div>
            <div class="card">
              <div class="image" style="background-image: url('/assets/images/team/gonxhe.jpg')"></div>
              <p class='name'>Gonxhe Rafuna</p>
              <p class="position">Marketing Sales Manager</p>
            </div>

            <div class="card">
              <div class="image" style="background-image: url('/assets/images/team/blerina.jpeg')"></div>
              <p class='name'>Blerina Rafuna</p>
              <p class="position">Coordinator & Admin Manager</p>
            </div>

            <div class="card">
              <div class="image" style="background-image: url('/assets/images/team/ediola.jpeg')"></div>
              <p class='name'>Ediola Mehmedi</p>
              <p class="position">Marketing Manager Assist</p>
            </div>

            <div class="card left">
              <div class="image" style="background-image: url('/assets/images/team/beni.jpeg')"></div>
              <p class='name'>Benjamin Çollakoviq</p>
              <p class="position">Sales Agent</p>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
  <app-map></app-map>
</div>
