<div class="home-plc" *ngIf="configs">
    <div class="background-image">
        <div class="image" [ngStyle]="{'background-image': 'url(' + configs.image + ')'}">
            <div class="shadow"></div>
            <app-home-banner-titles></app-home-banner-titles>

          <app-filters></app-filters>

        </div>
    </div>

    <div *ngIf="!sellingProducts.length && !rentProducts.length && !loading" style="text-align: center">
        <h3 class="mt-5 ml-5 no-prods">Për momentin nuk kemi produkte aktive per kliente!</h3>
    </div>

    <div class="slider-wrapper">
      <div class="spinner" *ngIf="loading">
        <mat-spinner color="accent"></mat-spinner>
      </div>

        <div class="slider" *ngIf="rentProducts.length">
            <p class="title">Objekte në Qira</p>

            <carousel style="height:320px" class="carousel" [autoplay]="true" [loop]="true" [cellsToScroll]="cellsToShow" [cellsToShow]="cellsToShow" [autoplayInterval]="3000">
                <div class="carousel-cell" *ngFor="let rentProd of rentProducts">
                    <app-product-card [product]="rentProd"></app-product-card>
                </div>
            </carousel>
        </div>

        <div class="slider" *ngIf="sellingProducts.length">
            <p class="title mt-4">Objekte në Shitje</p>

            <carousel style="height:320px" class="carousel" [autoplay]="true" [loop]="true" [cellsToScroll]="cellsToShow" [cellsToShow]="cellsToShow" [autoplayInterval]="3000">
                <div class="carousel-cell" *ngFor="let sellProd of sellingProducts">
                    <app-product-card [product]="sellProd"></app-product-card>
                </div>
            </carousel>
        </div>
    </div>
</div>
