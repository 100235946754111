<div class="product-card" *ngIf="product">
    <div class="wrapper">
        <div class="container">
          <div *ngIf="product.done" class="top pointer done" (click)="navigateToDetails(product.id)" >
            <img *ngIf="product.type == 'QIRA'" src="./assets/images/rented.png"/>
            <img *ngIf="product.type == 'SHITJE'" src="./assets/images/sold.png"/>
          </div>

          <div (click)="navigateToDetails(product.id)" class="top pointer" *ngIf="product.product_files && product.product_files.length && product.product_files[0].fileData.formats && product.product_files[0].fileData.formats.small"
              [ngStyle]="{ 'background-image': 'url(' + product.product_files[0].fileData.formats.small.url + ')'}"></div>

          <div (click)="navigateToDetails(product.id)" class="top pointer" *ngIf="product.product_files && product.product_files.length && product.product_files[0].fileData.formats && !product.product_files[0].fileData.formats.small"
          [ngStyle]="{ 'background-image': 'url(' + product.product_files[0].fileData.formats.thumbnail.url + ')'}"></div>

          <div (click)="navigateToDetails(product.id)" class="top pointer"
          *ngIf="product.product_files.length && product.product_files[0].fileData && product.product_files[0].fileData.url && product.product_files[0].fileData.url.includes('mp4')"
          [ngStyle]="{ 'background-image': 'url(/assets/images/card-preview.png)'}"></div>

          <div class="bottom">
            <div class="left">
              <div class="details">
                <h3 (click)="navigateToDetails(product.id)" class="pointer">{{product.title}}</h3>
                <p *ngIf="product.price">{{product.price}} €</p>
                <p *ngIf="!product.price">--</p>
              </div>
            </div>
            <div class="right"></div>
          </div>
        </div>
        <div class="inside">
          <div class="icon"><i class="material-icons">info_outline</i></div>
          <div class="contents">
            <table>
              <tr>
                <th>Nr i Dhomave</th>
                <th>M2</th>
              </tr>
              <tr>
                <td>{{product.rooms}}</td>
                <td>{{product.m2}}</td>
              </tr>
              <tr>
                <th>Adresa</th>
              </tr>
              <tr>
                <td>{{product.address}}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
</div>
