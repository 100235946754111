<div class="filters">
  <p class="filter-title">Filtro</p>

  <form class="d-flex m-0" [formGroup]="formGroup" (submit)="searchSubmit()">
    <div class="filter">
      <label>Nr. i Dhomave</label>
      <input formControlName="rooms" class="form-control" placeholder="Nr. i Dhomave" />
    </div>

    <div class="filter">
      <label>Tipi</label>
      <select class="form-control" formControlName="type">
        <option selected value="null">Të Gjitha</option>
        <option value="QIRA">Në Qira</option>
        <option value="SHITJE">Në Shitje</option>
      </select>
    </div>

    <div class="filter">
      <label>Produkt ID</label>
      <input formControlName="id" class="form-control" placeholder="Produkt ID" />
    </div>

    <div class="filter">
      <label>Adresa</label>
      <input formControlName="address" class="form-control" placeholder="Adresa" />
  </div>

    <div class="filter">
      <label>Nr. i Banjove</label>
      <input formControlName="bathroomNr" class="form-control" placeholder="Nr i Banjove" />
    </div>

    <div class="filter price">
      <label>Çmimi</label>
      <div class="d-flex">
        <input formControlName="pricegt" type="number" class="form-control" placeholder="Nga" />
        <p style="margin: 7px">-</p>
        <input formControlName="pricelt" type="number" class="form-control" placeholder="Deri në" />
      </div>
    </div>

    <div class="filter">
      <div class="button d-flex">
        <button mat-raised-button color="accent" class="mr-2">
          <mat-icon class="material-icon">search</mat-icon>
        </button>
        <button type="button" (click)="clearFilters()" mat-raised-button color="primary">Clear</button>
      </div>
    </div>
  </form>
</div>
