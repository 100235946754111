<div class="contact-details">
  <div class="title">
    <p>Detajet e Kontaktit</p>
  </div>

  <div class="details">
    <div class="row">
      <div class="col-md-3">Emri dhe Mbiemri</div>
      <div class="col-md-9">{{contact.full_name}}</div>
    </div>
    <div class="row">
      <div class="col-md-3">Emaili</div>
      <div class="col-md-9">{{contact.email}}</div>
    </div>
    <div class="row">
      <div class="col-md-3">Nr i Telefonit</div>
      <div class="col-md-9">{{contact.phone}}</div>
    </div>
    <div class="row">
      <div class="col-md-3">Mesazhi</div>
      <div class="col-md-9">{{contact.message}}</div>
    </div>
  </div>
</div>
