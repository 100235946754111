<div class="header-fixed" [ngClass]="user ? 'header-user' : ''">

  <div class="left-side">
    <div class="logo-plc">
      <a routerLink="/">
        <img src="{{logoUrl}}" />
      </a>
    </div>
  </div>

  <div class="right-side">
    <div class="links">
      <a routerLink="/">Ballina</a>
      <a routerLink="/product/list">Produktet</a>
      <a routerLink="/contact-us">Kontakti</a>
      <a routerLink="/about-us">Për ne</a>

      <button color="primary" *ngIf="user" mat-raised-button [matMenuTriggerFor]="menu">Admin</button>
      <mat-menu #menu="matMenu">
        <button routerLink="/contact-list" mat-menu-item>Lista e Kontakteve</button>
        <button routerLink="/configs" mat-menu-item>Konfigurime</button>
        <button mat-menu-item (click)="logOut()">Log Out</button>
      </mat-menu>
    </div>
  </div>

</div>
